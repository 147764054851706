import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import {
	Firecrawl,
	OptimizedContent,
	LongSummaryCollection,
	AllegationsCollection,
	EligibilitySummary,
	Core,
	GeneralQuestions,
	GeneralLogic,
	Complete,
} from "./subcollections/extractSteps"
import DateUpdated from "../properties/DateUpdated"
import DateCreated from "../properties/DateCreated"

export const ExtractCollection = buildCollection<ADMIN.Extract>({
	id: "extract",
	name: "Extract Data",
	singularName: "Extract Data",
	description: "Extract data pipeline",
	path: "extract",
	group: "tools",
	icon: "format_list_numbered",
	textSearchEnabled: true,
	initialSort: ["dateUpdated", "desc"],
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	propertiesOrder: [
		"url",
		"dateUpdated",
		"dateCreated",
		"skipExtraction",
		"summary",
		"subcollection:firecrawl",
		"subcollection:optimizedContent",
		"subcollection:longSummaryCollection",
		"subcollection:allegationsCollection",
		"subcollection:eligibilitySummary",
		"subcollection:core",
		"subcollection:generalQuestions",
		"subcollection:generalLogic",
		"subcollection:complete",
	],
	subcollections: [
		Firecrawl,
		OptimizedContent,
		LongSummaryCollection,
		AllegationsCollection,
		EligibilitySummary,
		Core,
		GeneralQuestions,
		GeneralLogic,
		Complete,
	],
	properties: {
		url: {
			name: "URL",
			dataType: "string",
			url: true,
		},
		summary: {
			name: "Allegations",
			dataType: "string",
		},
		skipExtraction: {
			name: "Skip Extraction",
			dataType: "boolean",
			description: "Prevent this url from being run during an `extract` step. Useful for outdated or dead links.",
		},
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
})
