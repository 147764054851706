import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import DateCreated from "../../properties/DateCreated"
import { SettlementsCollection } from "../settlements"

export const Page = buildCollection<ADMIN.Page>({
	id: "firecrawlPage",
	name: "Page",
	singularName: "Page",
	path: "pages",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		pageName: {
			dataType: "string",
			name: "Page Name",
		},
		markdown: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
		metadata: {
			dataType: "map",
			name: "metadata",
			keyValue: true,
			readOnly: true,
		},
	},
})

export const Firecrawl = buildCollection<ADMIN.Firecrawl>({
	id: "firecrawl",
	name: "Firecrawl",
	singularName: "Firecrawl",
	path: "firecrawl",
	description: "A history of the crawled settlement website",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	subcollections: [Page],
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
	},
})

export const OptimizedContent = buildCollection<ADMIN.OptimizedContent>({
	id: "optimizedContent",
	name: "Optimized",
	singularName: "Optimized",
	path: "optimizedContent",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		optimizedContent: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const LongSummaryCollection = buildCollection<ADMIN.LongSummaryCollection>({
	id: "longSummaryCollection",
	name: "Long Summary",
	singularName: "Long Summary",
	path: "longSummaryCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		longSummary: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const AllegationsCollection = buildCollection<ADMIN.AllegationsCollection>({
	id: "allegationsCollection",
	name: "Allegations",
	singularName: "allegations",
	path: "allegationsCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		allegations: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const EligibilitySummary = buildCollection<ADMIN.EligibilitySummary>({
	id: "eligibilitySummary",
	name: "EligibilitySummary",
	singularName: "EligibilitySummary",
	path: "eligibilitySummary",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		eligibilitySummary: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const Core = buildCollection<ADMIN.Core>({
	id: "core",
	name: "Core",
	singularName: "Core",
	path: "core",
	description: "The base settlement object (without the form)",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		settlement: {
			name: "Settlement Object",
			dataType: "map",
			keyValue: true,
			readOnly: true,
		},
	},
})

export const GeneralQuestions = buildCollection<ADMIN.GeneralQuestions>({
	id: "generalQuestions",
	name: "General Questions",
	singularName: "General Question",
	path: "generalQuestions",
	description: "A list of questions used to determine eligibility",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		form: {
			name: "form",
			dataType: "array",
			readOnly: true,
			of: {
				dataType: "map",
				keyValue: true,
			},
		},
	},
})

export const GeneralLogic = buildCollection<ADMIN.GeneralLogic>({
	id: "generalLogic",
	name: "General Logic",
	singularName: "General Logic",
	path: "generalLogic",
	description: "The form with logic added",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		form: {
			name: "form",
			dataType: "array",
			readOnly: true,
			of: {
				dataType: "map",
				keyValue: true,
			},
		},
	},
})

export const Complete = buildCollection<ADMIN.Settlement>({
	...SettlementsCollection,
	id: "complete",
	name: "Complete",
	singularName: "Complete",
	description: "A test object all the steps are written to",
	path: "complete",
	initialSort: ["dateUpdated", "desc"],
	selectionEnabled: false,
	defaultSize: "xs",
	permissions: () => ({
		read: true,
		edit: false,
		create: false,
		delete: true,
	}),
	properties: {
		...SettlementsCollection.properties,
	},
})
