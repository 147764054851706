import { buildEntityCallbacks, type EntityOnSaveProps, type DataSource } from "@firecms/core"
import { createSlug } from "utils/createSlug"
import type { ADMIN } from "types"

export const settlementsCallbacks = buildEntityCallbacks<ADMIN.Settlement>({
	onSaveSuccess: async (props: EntityOnSaveProps<ADMIN.Settlement>) => {
		const { entityId, values, context, collection, status } = props
		const dataSource: DataSource = context.dataSource

		// Check if "isRequired" is false or null, set "proofSummary" to null
		const isRequired = values.proof?.isRequired ?? null
		let proofSummary = values.proof?.proofSummary ?? null

		if (isRequired === false || isRequired === null) {
			proofSummary = null
		}

		// Update slug based on the title
		let slug = values.slug

		if (values.title) {
			slug = createSlug(values.title)
		}

		const updatedValues = {
			...values,
			slug,
			proof: {
				...values.proof,
				isRequired,
				proofSummary,
			},
		}

		await dataSource.saveEntity({
			path: "_settlements",
			entityId: entityId,
			values: updatedValues,
			collection: collection,
			status: status,
		})

		console.log("Document updated with new slug and proof fields", updatedValues)
	},
	onSaveFailure: (props: EntityOnSaveProps<ADMIN.Settlement>) => {
		console.log("Failed to save document", props)
	},
})
