import { type PropertyBuilderProps, buildCollection, buildProperties, buildProperty } from "@firecms/core"
import type { ADMIN } from "types"
import LogicBuilder from "../components/LogicBuilder"
import enumColors from "../utils/enumColors"
import DomainProperty from "../properties/DomainProperty"
import DateCreated from "../properties/DateCreated"
import DateUpdated from "../properties/DateUpdated"
import { settlementsCallbacks } from "../utils/settlementsCallbacks"

export const _SettlementsCollection = buildCollection<ADMIN.Settlement>({
	id: "_settlements",
	name: "Settlements (Private)",
	singularName: "Settlements (Private)",
	description: "A list of class action settlements",
	path: "_settlements",
	icon: "gavel",
	group: "private",
	selectionEnabled: false,
	textSearchEnabled: true,
	defaultSize: "xs",
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	properties: {
		url: {
			name: "Url",
			description: "The homepage of the class action website",
			dataType: "string",
			url: true,
			editable: false,
			validation: { required: true },
		},
		title: {
			name: "Title",
			dataType: "string",
			description: "Use the domain name. https://facebookuserprivacysettlement.com => Facebook User Privacy",
			longDescription: "Unique, human readable name we can use to refer to the case.",
			validation: { unique: true },
		},
		slug: {
			name: "slug",
			dataType: "string",
			description: "Settlement's page url, based on page title",
			readOnly: true,
			validation: { unique: true },
		},
		manualOverrides: {
			name: "Manual Overrides",
			dataType: "map",
			spreadChildren: true,
			properties: {
				isVisible: {
					name: "Is Visible",
					clearable: true,
					description: "Ignore the rest of 'status' and 'dates'. Make the case visible to users.",
					dataType: "string",
					enumValues: [
						{ label: "show", id: "show" },
						{ label: "hide", id: "hide" },
					],
				},
				isFeatured: {
					name: "Is Featured",
					dataType: "boolean",
					description: "Should this appear on the Landing Page?",
				},
				weight: {
					name: "Weight",
					description: "More important cases have a higher weight",
					dataType: "number",
				},
			},
		},
		courtCases: {
			name: "Court Cases",
			dataType: "array",
			description: "Most cases will only have 1 associated case number",
			longDescription:
				"Some cases will have multiple numbers. For example, multiple cases filed against the same company at similar times. Or, FTC cases that also have associated Federal District court cases. Or, cases brought in both state and federal courts.",
			hideFromCollection: true,
			expanded: false,
			of: {
				dataType: "reference",
				path: "_courtCases",
				previewProperties: ["name", "caseNumber"],
			},
		},
		defendants: {
			name: "Defendants",
			dataType: "array",
			description: "Who is being sued?",
			hideFromCollection: true,
			expanded: false,
			of: {
				dataType: "reference",
				path: "_defendants",
				previewProperties: ["name"],
			},
		},
		brands: {
			name: "Brands",
			dataType: "array",
			description: "What's the brand name of the product, service, or company involved?",
			expanded: false,
			hideFromCollection: true,
			of: {
				dataType: "reference",
				path: "_brands",
				previewProperties: ["name"],
			},
		},
		firms: {
			name: "Law Firms",
			dataType: "array",
			description: "The Law Firms representing parties in the case",
			hideFromCollection: true,
			expanded: false,
			of: {
				dataType: "reference",
				path: "_firms",
				previewProperties: ["name"],
			},
		},
		category: buildProperty({
			name: "Category",
			dataType: "string",
			description: "Which category best fits the case?",
			longDescription:
				"https://docs.google.com/document/d/1bnOyczEOvSzVn9cSqWYM_QiPg5EwG0uLx7kLRq1_RlQ/edit?usp=sharing",
			enumValues: {
				unfairPracticesAndDeception: "Unfair Practices & Deception",
				financialMisconduct: "Financial Misconduct",
				workplaceRights: "Workplace Rights",
				healthAndSafety: "Health & Safety",
				privacyViolations: "Privacy Violations",
				accessAndEquality: "Access & Equality",
				productAndServiceFailures: "Product & Service Failures",
			},
		}),
		domains: {
			name: "Domains",
			dataType: "array",
			description: "How can we identify who is eligible to apply?",
			longDescription:
				"https://docs.google.com/document/d/1bnOyczEOvSzVn9cSqWYM_QiPg5EwG0uLx7kLRq1_RlQ/edit?usp=sharing",
			expanded: false,
			of: DomainProperty,
		},
		reason: {
			name: "Reason",
			dataType: "string",
			description: "What's the specific reason the case is happening?",
			longDescription: "e.g. 'Contaminated Food', 'Undisclosed Fees', 'Unpaid Overtime', 'Data Breach', etc.",
		},
		allegations: {
			name: "Allegations",
			dataType: "string",
			description: "TODO: NEEDS DESCRIPTION",
			hideFromCollection: true,
			markdown: true,
		},
		eligibilitySummary: {
			name: "Eligibility Summary",
			dataType: "string",
			description: "TODO: NEEDS DESCRIPTION",
			hideFromCollection: true,
			markdown: true,
		},
		value: {
			name: "Value",
			dataType: "map",
			hideFromCollection: true,
			expanded: false,
			properties: {
				total: {
					name: "Total",
					description: "What's the total the defendant settled for?",
					longDescription: "e.g. $5,000,000",
					dataType: "number",
				},
				totalMin: {
					name: "Total Min",
					description: "What's the minimum one person will get?",
					longDescription: "e.g. at least $200, more if you meet other criteria",
					dataType: "number",
				},
				totalMax: {
					name: "Total Max",
					description: "What's the maximum one person will get?",
					longDescription: "e.g. up to $25",
					dataType: "number",
				},
				individual: {
					name: "Individual",
					description: "Roughly, what will one person get?",
					dataType: "number",
				},
				individualMin: {
					name: "Individual Min",
					description: "What's the minimum one person will get?",
					longDescription: "e.g. at least $200, more if you meet other criteria",
					dataType: "number",
				},
				individualMax: {
					name: "Individual Max",
					description: "What's the maximum one person will get?",
					longDescription: "e.g. up to $25",
					dataType: "number",
				},
			},
		},
		dates: {
			name: "Dates",
			previewProperties: ["applyDeadline"],
			description: "The important dates of this case",
			dataType: "map",
			expanded: false,
			spreadChildren: true,
			hideFromCollection: false,
			properties: {
				applyDeadline: {
					name: "Apply",
					description: "What's the last day to apply for the case?",
					dataType: "date",
					mode: "date",
					hideFromCollection: false,
				},
				objectDeadline: {
					name: "Object",
					description: "What's the last day to object to the case?",
					dataType: "date",
					mode: "date",
					hideFromCollection: true,
				},
				optOutDeadline: {
					name: "Opt Out",
					description: "What's the last day to opt-out of the case?",
					dataType: "date",
					mode: "date",
					hideFromCollection: true,
				},
				approvalHearingDate: {
					name: "Approval Hearing",
					description: "When is the the final approval hearing?",
					dataType: "date",
					mode: "date",
					hideFromCollection: true,
				},
				expectedPaymentDate: {
					name: "ExpectedPayment",
					description: "When will people get their money?",
					dataType: "date",
					mode: "date",
				},
				trialDate: {
					name: "Trial Date",
					description: "When is the case scheduled to go to trial?",
					dataType: "date",
					mode: "date",
					hideFromCollection: true,
				},
			},
		},
		proof: ({ values }) => {
			const proofProperties = buildProperties<any>({
				isRequired: {
					dataType: "boolean",
					name: "Is Required",
					description: "Indicates if proof is required",
				},
			})
			if (values.proof?.isRequired) {
				proofProperties.proofSummary = buildProperty({
					dataType: "string",
					name: "Proof Summary",
					description: "Summary of the proof",
					validation: { required: true },
				})
			}
			return {
				dataType: "map",
				name: "Proof",
				properties: proofProperties,
			}
		},
		status: {
			name: "Status",
			dataType: "map",
			expanded: false,
			spreadChildren: true,
			properties: {
				isInvestigationConcluded: {
					name: "Investigation Concluded",
					dataType: "boolean",
					hideFromCollection: true,
				},
				isFiled: {
					name: "Filed",
					dataType: "boolean",
					hideFromCollection: true,
				},
				isAppealed: {
					name: "Is Appealed",
					dataType: "boolean",
					hideFromCollection: true,
				},
				isDismissed: {
					name: "Is Dismissed",
					dataType: "boolean",
					hideFromCollection: true,
				},
				hasClassCertification: {
					name: "Has Class Certification",
					dataType: "boolean",
					hideFromCollection: true,
				},
				hasPreliminarySettlementAgreement: {
					name: "Has Preliminary Settlement Agreement",
					dataType: "boolean",
					hideFromCollection: true,
				},
				isNoLongerActive: {
					name: "Is No Longer Active",
					dataType: "boolean",
				},
				hasFinalCourtApproval: {
					name: "Has Final Court Approval",
					dataType: "boolean",
					hideFromCollection: true,
				},
				areAllEligibleClaimantsIdentified: {
					name: "All Eligible Claimants Identified",
					dataType: "boolean",
				},
				areClaimsRequired: {
					name: "Claims Required",
					dataType: "boolean",
				},
				claimsOpenStatus: {
					name: "Claims Open Status",
					dataType: "string",
					enumValues: [
						{ id: "notYetOpen", label: "notYetOpen" },
						{ id: "ongoing", label: "ongoing" },
						{ id: "complete", label: "complete" },
					],
				},
				claimsReviewStatus: {
					name: "Claims Review Status",
					dataType: "string",
					enumValues: [
						{ id: "notYetReviewed", label: "notYetReviewed" },
						{ id: "ongoing", label: "ongoing" },
						{ id: "complete", label: "complete" },
					],
				},
				claimsSubmitMethods: {
					name: "Claims Submit Methods",
					dataType: "array",
					hideFromCollection: true,
					of: {
						dataType: "string",
						enumValues: [
							{ id: "online", label: "online" },
							{ id: "phone", label: "phone" },
							{ id: "mail", label: "mail" },
						],
					},
				},
				paymentStatus: {
					name: "Payment Status",
					dataType: "string",
					hideFromCollection: true,
					enumValues: [
						{ id: "unknown", label: "unknown" },
						{ id: "notYetStarted", label: "notYetStarted" },
						{ id: "ongoing", label: "ongoing" },
						{ id: "delayed", label: "delayed" },
						{ id: "complete", label: "complete" },
					],
				},
				isCanadian: {
					name: "is Canadian",
					description:
						"Only set this value to true if Americans are not allowed to apply. For example: A settlement filed in a Canadian Court, or open to Canadian citizens only.",
					dataType: "boolean",
					hideFromCollection: true,
				},
			},
		},
		form: {
			name: "Form",
			description: "List of questions used to test eligibility",
			dataType: "array",
			hideFromCollection: false,
			of: (questionProps: PropertyBuilderProps) => ({
				name: "Question",
				dataType: "map",
				expanded: false,
				properties: {
					justification: {
						name: "justification",
						dataType: "string",
						readOnly: true,
					},
					question: {
						name: "Linked Question",
						description: "A question that belongs to this form",
						dataType: "reference",
						path: "generalQuestions",
						previewProperties: ["text", "type"],
					},
					logicJumps: {
						name: "Logic Jumps",
						description: "List of list of jumps to execute based on different outcomes.",
						dataType: "array",
						expanded: false,
						oneOf: {
							typeField: "type",
							properties: {
								question: {
									name: "Question",
									dataType: "map",
									properties: {
										condition: buildProperty({
											name: "Conditions",
											dataType: "string",
											Field: LogicBuilder,
											customProps: {
												question: questionProps?.propertyValue?.question,
											},
										}),
										question: {
											name: "Question",
											description:
												"The 'ref' value for the next question the Logic Jump leads to.",
											dataType: "reference",
											path: "generalQuestions",
											previewProperties: ["text"],
										},
									},
								},
								result: {
									name: "Result",
									dataType: "map",
									properties: {
										condition: buildProperty({
											name: "Conditions",
											dataType: "string",
											Field: LogicBuilder,
											customProps: {
												question: questionProps?.propertyValue?.question,
											},
										}),
										result: {
											name: "Result",
											description: "The result of the form.",
											dataType: "string",
											enumValues: [
												{
													id: "eligible",
													label: "Eligible",
													color: enumColors.green,
												},
												{
													id: "possiblyEligible",
													label: "Possibly Eligible",
													color: enumColors.yellow,
												},
												{
													id: "inEligible",
													label: "Ineligible",
													color: enumColors.red,
												},
											],
										},
									},
								},
							},
						},
					},
				},
			}),
		},
		llmComments: {
			name: "LLM Comments",
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
	callbacks: settlementsCallbacks,
})
